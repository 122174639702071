import crypto from 'crypto'
import React, {
    MutableRefObject,
    ReactElement,
    useRef,
    useState,
    Children,
    SyntheticEvent,
} from 'react'
import 'react-image-lightbox/style.css'

import {
    validateFileSize,
    validateFileType,
} from '../../../services/fileValidatorService'

import imageFile from '../../../assets/images/image_file.svg'
import { tokenToUpload } from '../../../auth/auth'
import { postMessageToRN } from '../../../utils'
import {
    Button,
    Container,
    DeleteUploadedImage,
    Input,
    UploadedImage,
    UploadedImageCard,
} from './style'
import Lightbox from 'react-image-lightbox'
import trash from '../../../assets/images/trash.svg'

type FileGroupProps = {
    children: ReactElement[]
    onDelete?(imageIndex: number): void
    onUpload?(filename: string): any
}

export const FileGroup: React.FC<FileGroupProps> = (props: FileGroupProps) => {
    const { children, onDelete, onUpload } = props

    const width = 100 / Children.count(children)

    return (
        <Container>
            {Children.map(children, (element) =>
                React.cloneElement(element, {
                    ...element.props,
                    width,
                    onDelete,
                    onUpload,
                })
            )}
        </Container>
    )
}

FileGroup.defaultProps = {
    onDelete() {
        return false
    },
    onUpload() {
        return false
    },
}

type FileProps = {
    imageIndex: number
    image: string
    setImage(path: string): void
    width?: number
    imageMaxSize?: number
    onDelete?(imageIndex: number): void
    onUpload?(filename: string): any
}

export const ImageFile: React.FC<FileProps> = (props: FileProps) => {
    const {
        imageIndex,
        image,
        setImage,
        width,
        imageMaxSize,
        onDelete,
        onUpload,
    } = props

    const hiddenFileInput = useRef() as MutableRefObject<HTMLInputElement>

    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const handleClick = () => {
        hiddenFileInput.current.click()
    }

    const handleImagePreview = async (element: HTMLInputElement) => {
        const reader = new FileReader()
        const file = element.files

        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(String(reader.result))
            }
        }

        if (file === null) return
        if (imageMaxSize === undefined) return

        const validFileSize = await validateFileSize(file[0].size, imageMaxSize)
        const validFileType = await validateFileType(file[0].type, [
            'image/jpg',
            'image/jpeg',
            'image/png',
        ])

        if (!validFileSize.isValid) {
            alert(validFileSize.errorMessage)
            return
        }
        if (!validFileType.isValid) {
            alert(validFileType.errorMessage)
            return
        }

        if (validFileType.isValid && validFileSize.isValid) {
            setImage(file[0].name)

            reader.readAsDataURL(file[0])
        }
    }

    const removeImagePreview = () => {
        setImage('')
        hiddenFileInput.current.value = ''

        if (onDelete) {
            onDelete(imageIndex)
        }
    }

    const RenderFileFields = () => {
        const service = localStorage.getItem(
            `${
                window.location.hash?.split('/')[1].split('#')[0]
            }_@services_service`
        )
        if (service === 'ANDROID' || service === 'IOS') {
            const openCameraMobile = () => {
                const filename = crypto
                    .createHmac('sha256', '$ome$alt')
                    .update(`${tokenToUpload}${Date.now()}`)
                    .digest('hex')
                const args = `#upload-image.${filename}`

                if (postMessageToRN(args)) {
                    if (onUpload) onUpload(filename)
                }
            }

            return (
                <Button type='button' onClick={() => openCameraMobile()}>
                    <img src={imageFile} alt='' />
                </Button>
            )
        }
        return (
            <>
                <Button type='button' onClick={() => UploadImage()}>
                    ALOU
                </Button>
            </>
        )
    }

    const UploadImage = () => {
        const filename = crypto
            .createHmac('sha256', '$ome$alt')
            .update(`${tokenToUpload}${Date.now()}`)
            .digest('hex')

        console.log(filename)
        // if (onUpload) onUpload(filename)
    }

    // return RenderFileFields()
    return (
        <>
            <Input
                key={imageIndex}
                type='file'
                accept='image/png,image/jpg,image/jpeg'
                name={`Imagem${imageIndex}`}
                ref={hiddenFileInput}
                onChange={(event: SyntheticEvent) =>
                    handleImagePreview(event.currentTarget as HTMLInputElement)
                }
            />
            {image.length <= 0 ? (
                <>
                    <Button type='button' onClick={handleClick} width={width}>
                        <img src={imageFile} alt='' />
                    </Button>
                </>
            ) : (
                <>
                    <UploadedImageCard width={width}>
                        <UploadedImage onClick={() => setModalOpen(true)}>
                            <img src={image} alt='' />
                        </UploadedImage>

                        <DeleteUploadedImage
                            type='button'
                            onClick={removeImagePreview}>
                            <img src={trash} alt='' />
                        </DeleteUploadedImage>
                    </UploadedImageCard>

                    {modalOpen && (
                        <Lightbox
                            mainSrc={image}
                            onCloseRequest={() => setModalOpen(false)}
                        />
                    )}
                </>
            )}
        </>
    )
}

ImageFile.defaultProps = {
    width: 100,
    imageMaxSize: 5,
    onDelete() {
        return false
    },
    onUpload() {
        return false
    },
}
