/* eslint-disable no-param-reassign */
import axios from 'axios'
import { asyncLocalStorage } from '../auth/auth'

// Lista de URLs a verificar
const urlsToCheck = [
    'services.simplificaci.com.br',
    'services-beta.simplificaci.com.br',
    'yellow-water',
    'salmon-stone',
]

// Função para obter a URL base dinâmica
const getBaseURL = async (service: string, serviceURL: string) => {
    const isLocalhost = window.location.href.includes('localhost')
    const isNgrok = window.location.href.includes('ngrok')
    const isProduction = urlsToCheck.some(url => window.location.href.includes(url))

    if (service.includes('ngrok')) {
        console.log('service', service, 'ngrok')
        return `https://${service}/services`
    }
    
    if (isLocalhost || isNgrok) {
        if (service.includes('ngrok')) {
            console.log('service', service, 'ngrok')
            return `https://${service}/services`
        }
        return `https://api-[service].simplificaci.com.br/services`.replace(
            '[service]',
            serviceURL
        )
    }

    if (isProduction) {
        return `https://api-[service].simplificaci.com.br/services`.replace(
            '[service]',
            serviceURL
        )
    }

    if (window.location.href.includes('services.simplificaci.com.br')) {
        return `https://api-[service].simplificaci.com.br/services`.replace(
            '[service]',
            serviceURL
        )
    }

    return ''
}

// Função para determinar o tipo de serviço
const getServiceURL = (service: string) => {
    console.log('service', service)
    if (['ANDROID', 'IOS', 'MOBILE'].includes(service)) {
        return 'mobile'
    }
    if (['DESK', 'DESKTOP'].includes(service)) {
        return 'desk'
    }
    return ''
}

// Instância Axios configurada
const axiosInstance = axios.create({
    baseURL: '',
    timeout: 10000,
})

// Interceptor para configurar requisições
axiosInstance.interceptors.request.use(async (config) => {
    try {
        const token = await asyncLocalStorage.getItem('@services_token')
        const colCodigo = await asyncLocalStorage.getItem('@services_colCodigo')
        config.headers.Authorization = token ? `bearer ${token}` : 'application/x-www-form-urlencoded'

        if (!config.headers['Token']) {
            config.headers['Token'] = colCodigo
        }

        const module = await asyncLocalStorage.getItem('@services_module')
        const service = await asyncLocalStorage.getItem('@services_service')

        if (service) {
            
            const serviceURL = getServiceURL(service)
            console.log('serviceURL', serviceURL)
            config.baseURL = await getBaseURL(service, serviceURL)

            if (config?.url === '/security/token') {
                config.baseURL = config.baseURL.replace('/services', '')
            }

        }
    } catch (error) {
        console.error('Error configuring request:', error)
    }
    return config
})

// Interceptor para tratar respostas
axiosInstance.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        if (!error.response) {
            throw new Error('Network Error')
        }

        if (error.response.status === 404 && error.config.baseURL === '') {
            const serviceURL = JSON.parse(error.config.data)
                .hash.split('#')[1]
                .split('')[1]
                .split('|')[0]

            setTimeout(() => {
                window.location.href = `/#/${serviceURL}#${JSON.parse(error.config.data).hash}`
            }, 1000)
        }

        return Promise.reject(error)
    }
)

export const api = axiosInstance
