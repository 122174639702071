export enum SettingsTypes {
    LOAD_SETTINGS_SETUP = '@settings/LOAD_SETTINGS_SETUP',
    LOAD_SETTINGS_REQUEST = '@settings/LOAD_SETTINGS_REQUEST',
    LOAD_SETTINGS_SUCCESS = '@settings/LOAD_SETTINGS_SUCCESS',
    LOAD_SETTINGS_FAILURE = '@settings/LOAD_SETTINGS_FAILURE',

    LOAD_SETTINGS_OPTIONS = '@settings/LOAD_SETTINGS_OPTIONS',

    UPDATE_SETTINGS = '@settings/UPDATE_SETTINGS',

    SET_SETTINGS_STATE = '@settings/SET_SETTINGS_STATE',
}

//PROPS
interface CommonProps {
    setSettingsState: (field: TSettingsField, value: any) => void
    settingsAvailable: TSettingsAvailable
}

export interface IProfileProps extends CommonProps {
    nomeSocial: string
    genero: string
    pronome: string
    options: ISettingsOptions
}

export interface IAccessibilityProps extends CommonProps {
    modoNoturno: boolean
    filtroCor: string
    contraste: string
    tamanhoFonte: string
    libras: boolean
    leituraVoz: boolean
}

export interface ISecurityProps extends CommonProps {
    usarBiometria: boolean
}

export interface INotificationsProps extends CommonProps {
    aniversarioVida: boolean
}

export type TSettingsField =
    | 'nomeSocial'
    | 'genero'
    | 'pronome'
    | 'modoNoturno'
    | 'filtroCor'
    | 'contraste'
    | 'tamanhoFonte'
    | 'leituraLibras'
    | 'leituraVoz'
    | 'usarBiometria'
    | 'aniversarioVida'

export interface ISettingsSetup {
    showAcessibilidade: boolean
    showSeguranca: boolean
    showNotificacoes: boolean

    settingsAvailable: TSettingsAvailable
    TituloModulo: string
    CorPrimaria: string
    CorSecundaria: string
    EsconderHeader: boolean

    isLoaded: boolean
}

export type TSettingsAvailable = {
    nomeSocial: boolean
    genero: boolean
    pronome: boolean
    modoNoturno: boolean
    filtroCor: boolean
    contraste: boolean
    tamanhoFonte: boolean
    leituraLibras: boolean
    leituraVoz: boolean
    usarBiometria: boolean
    aniversarioVida: boolean
}

export interface ISettingsOption {
    text: string
    value: string
}

export interface ISettingsOptions {
    colorFilters: Array<ISettingsOption>
    genders: Array<ISettingsOption>
    pronouns: Array<ISettingsOption>
}

export interface ISettings {
    nomeSocial: string
    genero: string
    pronome: string
    modoNoturno: boolean
    filtroCor: string
    contraste: string
    tamanhoFonte: string
    leituraLibras: boolean
    leituraVoz: boolean
    usarBiometria: boolean
    aniversarioVida: boolean
    isLoaded?: boolean
}

export interface SettingsState {
    readonly settings: ISettings
    readonly setup: ISettingsSetup
    readonly options: ISettingsOptions
    readonly pronome: string
    readonly loading: boolean
    readonly updateLoading: boolean
    readonly error: boolean
    readonly errorMessage: string
}
