/* eslint-disable no-bitwise */

import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import LanguageDetector from 'i18next'
import qs from 'qs'
import { Dispatch } from 'redux'
import { asyncLocalStorage } from '../../../auth/auth'
import { api } from '../../../services/api'
import * as AppActions from './actions'

const AppThunk = {
    refreshTokenTimeout: 0 as unknown as NodeJS.Timer,

    loadToken: (hash: string) => (dispatch: Dispatch) => {
        dispatch(AppActions.loadTokenRequest())

        const service = hash.split(';')[0]
        let hashValue = hash.split(';')[1]
        let colCodigo = hash.split(';')[2]

        if (service.indexOf('ngrok') > -1) {
            hashValue = hash.split(';')[2]
            colCodigo = hash.split(';')[3]
        }

        const data = qs.stringify({ grant_type: 'password' })

        if(!!colCodigo)
            asyncLocalStorage.setItem('@services_colCodigo', colCodigo)

        const options = {
            headers: {
                Username: '_pnmLw3GToLK^TiskBL^*gxGQ+Mxj',
                'Access-Type': 'services',
                Token: hashValue,
                Language: LanguageDetector.language,
            },
        }

        asyncLocalStorage.setItem('@services_service', service).then(() => {
            api.post(`/security/token`, data, options)
                .then((response: AxiosResponse) => {
                    AppThunk.tokenSuccess(response)(dispatch)
                    dispatch(AppActions.setAppService(service))
                })
                .catch((error: AxiosError) => {
                    Sentry.captureException(error)
                    dispatch(AppActions.loadTokenFailure(`${error.message}`))
                })
        })
    },

    refreshToken: (refreshToken: string) => (dispatch: Dispatch) => {
        const data = qs.stringify({
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        })
        api.post('/security/token', data)
            .then((response: AxiosResponse) => {
                if (response.data) {
                    AppThunk.tokenSuccess(response)(dispatch)
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error)
                dispatch(AppActions.loadTokenFailure(`${error.message}`))
            })
    },

    tokenSuccess: (response: AxiosResponse) => (dispatch: Dispatch) => {
        if (response.data?.token_type === 'bearer') {
            const serviceStorage = new Promise<void>((res, rej) => {
                asyncLocalStorage.setItem(
                    '@services_token',
                    response.data?.access_token
                )
                asyncLocalStorage.setItem(
                    '@services_token_refresh',
                    response.data?.refresh_token
                )
                asyncLocalStorage
                    .setItem(
                        '@services_token_expires_in',
                        response.data.expires_in
                    )
                    .then(() => {
                        res()
                    })
            })

            serviceStorage.then(() => {
                dispatch(AppActions.loadTokenSuccess())
                clearTimeout(AppThunk.refreshTokenTimeout)
            
                const threeHours = 10800000 // 10.800.000 ms
            
                AppThunk.refreshTokenTimeout = setTimeout(() => {
                    AppThunk.refreshToken(response.data.refresh_token)(dispatch)
                }, threeHours - 60000) // 1 minuto antes de completar as 3 horas
            })
        }
    },

    nativeUploadFile:
        (filename: string, route: string, uploadTry = 0) =>
        (dispatch: Dispatch) => {
            dispatch(AppActions.loadUploadRequest())

            if (uploadTry <= 3) {
                setTimeout(() => {
                    api.post(route, { filename })
                        // eslint-disable-next-line consistent-return
                        .then((response: any) => {
                            if (response.data.state) {
                                return response.data
                            }

                            AppThunk.nativeUploadFile(
                                filename,
                                route,
                                uploadTry + 1
                            )(dispatch)
                        })
                }, 5000)
            } else {
                dispatch(
                    AppActions.loadUploadFailure(
                        'Não foi possivel realizar o upload do arquivo'
                    )
                )
            }
        },

        logout: (colCodigo:number) => (dispatch: Dispatch) => {
            localStorage.clear()
            dispatch(AppActions.logout())
        }
}
export default AppThunk
