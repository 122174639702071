import { action } from 'typesafe-actions'
import {
    ISettings,
    ISettingsSetup,
    SettingsTypes,
    TSettingsField,
} from './types'

export const loadSettingsRequest = () =>
    action(SettingsTypes.LOAD_SETTINGS_REQUEST)

export const loadSettingsSetup = (data: ISettingsSetup) =>
    action(SettingsTypes.LOAD_SETTINGS_SETUP, { data })

export const loadSettingsSuccess = (data: ISettings) =>
    action(SettingsTypes.LOAD_SETTINGS_SUCCESS, { data })

export const loadSettingsFailure = (error: any) =>
    action(SettingsTypes.LOAD_SETTINGS_FAILURE, error)

export const loadSettingsOptions = (data: Array<string>) =>
    action(SettingsTypes.LOAD_SETTINGS_OPTIONS, { data })

export const setSettingsState = (field: TSettingsField, value: any) =>
    action(SettingsTypes.SET_SETTINGS_STATE, { field, value })

    export const updateSettings = (loading: boolean) => action(SettingsTypes.UPDATE_SETTINGS, { loading })
