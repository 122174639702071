import React from 'react'
import Switch from '../../../components/Switch'
import {useDebounce} from '../../../hooks/useDebounce'
import {
    INotificationsProps,
    TSettingsField,
} from '../../../store/containers/settings/types'
import { i18n } from '../../../translate/i18n'
import { FormContainer, FormGroup, Label, Title } from '../Screen/styles'

const Notifications: React.FC<INotificationsProps> = ({
    aniversarioVida,
    setSettingsState,
    settingsAvailable,
}) => {
    const handleChange = useDebounce((field: TSettingsField, value: string) => {
        setSettingsState(field, value)
    }, 2000)

    if (!settingsAvailable.aniversarioVida) return null

    return (
        <div>
            <Title>{i18n.t('settings.notifications')}</Title>
            <FormContainer>
                {settingsAvailable.aniversarioVida && (
                    <>
                        <Label>{i18n.t('settings.birthdays')}</Label>
                        <FormGroup inline>
                            <Label
                                style={{
                                    color: '#767676',
                                    fontWeight: 'normal',
                                }}
                                htmlFor='aniversarioVida'>
                                {i18n.t('settings.allow-birthdays')}
                            </Label>
                            <Switch
                                value={aniversarioVida}
                                onChange={() =>
                                    handleChange(
                                        'aniversarioVida',
                                        !aniversarioVida
                                    )
                                }
                            />
                        </FormGroup>
                    </>
                )}
            </FormContainer>
        </div>
    )
}

export default Notifications
