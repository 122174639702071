import React from 'react'
import { LoaderProps } from '../../types/index'
import {
    Container,
    ErrorMessage,
    ErrorTitle,
    LoadSpinner,
    Wrapper,
} from './style'

const Loader: React.FC<LoaderProps> = ({
    loading,
    error = false,
    errorMessage = 'Ocorreu um Erro!',
    children,
    show = false,
}) => {
    if (error) {
        return (
            <Container>
                <ErrorTitle>Oops!!</ErrorTitle>
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </Container>
        )
    }

    if (loading || show) {
        return (
            <Container>
                <LoadSpinner />
            </Container>
        )
    }

    return <Wrapper>{children}</Wrapper>
}

export default Loader
