const messages = {
    en: {
        translations: {
            general: {
                goBack: 'Back',
                day: 'Day',
                week: 'Week',
                month: 'Month',
                years: 'Years',
                year: 'Year',
                since: 'Since',
                save: 'Save',
                saving: 'Saving...',
                saved: 'Saved!',
            },
            birthdays: {
                notFound: 'No birthdays in this period',
            },
            birthwork: {
                notFound: 'No celebrations in this period',
            },
            documents: {
                see: 'See',
            },
            training: {
                todo: 'To Do',
                done: 'Done',
                'your-progress': 'Your Progress',
            },
            terms: {
                pendings: 'Pending',
                accepted: 'Accepted',
            },
            gifts: {
                'for-company': 'For exclusive use of the company:',
                'pin-note': 'Provide the access PIN to confirm the delivery.',
                'last-rescue': 'Date of last rescue:',
                'no-last-rescue': 'No rescues requested previously',
            },
            settings: {
                profile: 'Profile',
                'social-name': 'Social Name',
                'not-specified': 'Not specified',
                genero: 'Gender',
                'genero-list': {
                    masculine: 'Masculine',
                    feminine: 'Feminine',
                    'non-binary': 'Non-binary',
                    other: 'Other',
                },
                pronome: 'pronome',
                accessibility: 'Accessibility',
                'dark-mode': 'Dark Mode',

                'color-filter': 'Color Filter',
                'color-filter-protoanopia': 'Protanopia',
                'color-filter-deuteranopia': 'Deuteranopia',
                'color-filter-tritanopia': 'Tritanopia',

                contraste: 'Contrast',
                'contraste-low': 'Low',
                'contraste-normal': 'Normal',
                'contraste-high': 'High',

                'font-size': 'Font Size',
                'font-size-small': 'Small',
                'font-size-normal': 'Normal',
                'font-size-large': 'Large',

                libras: 'Libras',
                'text-to-speech': 'Read Text',

                security: 'Security',
                'app-activation': 'App Activation',
                'use-biometry': 'Use Biometry',

                notifications: 'Notices and Notifications',
                birthdays: 'Birthdays',
                'allow-birthdays': 'Allow Notifications',
            },
        },
    },
}

export { messages }
