import React, { useCallback, useEffect } from 'react';

import { PinButton, PinContainer, PinRow } from './style';

type Props = {
    pinValue: string,
    setPinValue(pinValue: string): void
    requestedPIN: () => void
}

const GiftPinGrid: React.FC<Props> = ({ requestedPIN, pinValue, setPinValue }: Props) => {

    const formatPIN = useCallback((value: string) => {
        setPinValue(pinValue + value)
    }, [pinValue, setPinValue])

    useEffect(() => {
        if (pinValue.length === 4) {
            requestedPIN()
            setPinValue("")
        }
    }, [pinValue, requestedPIN, setPinValue])

    return (
        <PinContainer>
            <PinRow>
                <PinButton onClick={() => formatPIN("1")}>1</PinButton>
                <PinButton onClick={() => formatPIN("2")}>2</PinButton>
                <PinButton onClick={() => formatPIN("3")}>3</PinButton>
            </PinRow>
            <PinRow>
                <PinButton onClick={() => formatPIN("4")}>4</PinButton>
                <PinButton onClick={() => formatPIN("5")}>5</PinButton>
                <PinButton onClick={() => formatPIN("6")}>6</PinButton>
            </PinRow>
            <PinRow>
                <PinButton onClick={() => formatPIN("7")}>7</PinButton>
                <PinButton onClick={() => formatPIN("8")}>8</PinButton>
                <PinButton onClick={() => formatPIN("9")}>9</PinButton>
            </PinRow>
        </PinContainer>
    )
}
export default GiftPinGrid;