import React, { useEffect } from 'react'
import { Redirect, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import DocumentsThunk from '../../store/containers/documents/thunk'
import Loader from '../../components/Loader'

const Documents: React.FC = () => {
    const { hash } = useLocation()
    const dispatch = useDispatch()

    const { loading, error, errorMessage } = useSelector((state: ApplicationState) => state.documents)

    useEffect(() => {
        if (hash) {
            const tokenHash = hash.split('#')[1] // Extração do token do hash
            if (tokenHash) {
                dispatch(DocumentsThunk.loadSetup(tokenHash))
            }
        }
    }, [hash, dispatch])

    return (
        <Loader loading={loading} error={error} errorMessage={errorMessage}>
            <Redirect to={{ pathname: '/docsrh/list' }} />
        </Loader>
    )
}

export default Documents
