import React from 'react'
import moment from 'moment'
import { IGift, IGiftSetup } from '../../../../store/containers/gifts/types'
import { GiftButton, GiftNote, Note } from './style'
import { i18n } from '../../../../translate/i18n'

type Props = {
    gifts: IGift
    giftSetup: IGiftSetup
    RequestGift: () => void
}

const GiftNotes: React.FC<Props> = ({
    gifts,
    RequestGift,
    giftSetup,
}: Props) => {
    const notesRender = () => {
        if (gifts?.status !== 'received' && gifts?.status) {
            return (
                <>
                    <Note>
                        <span>{i18n.t('gifts.for-company')}</span> <br />
                        {giftSetup.apps_brindes_informativo}
                    </Note>
                    <GiftButton onClick={RequestGift}>
                        {giftSetup.apps_brindes_botao}
                    </GiftButton>
                </>
            )
        }

        if (gifts?.ultimoResgate) {
            return (
                <Note>
                    <span>{i18n.t('gifts.last-rescue')}</span> <br /> <br />
                    {moment(gifts?.ultimoResgate).format('DD/MM/YYYY HH:mm')}
                </Note>
            )
        }

        return <Note>{i18n.t('gifts.no-last-rescue')}</Note>
    }

    return <GiftNote>{notesRender()}</GiftNote>
}

export default GiftNotes
