import { AxiosResponse } from 'axios'
import { asyncLocalStorage } from '../../../auth/auth'
import { api } from '../../../services/api'
import simplifica from '../../../styles/themes/simplifica'
import * as SettingsActions from './actions'
import { ISettings } from './types'

const SettingsThunk = {
    loadSetup: (hash: string) => async (dispatch: any) => {
        dispatch(SettingsActions.loadSettingsRequest())
        api.post('/settings/setup', { hash })
            .then((response: AxiosResponse) => {
                if (response.data.state) {
                    SettingsThunk.loadTheme(
                        JSON.parse(response.data.data)[0]
                    ).then(() => {
                        const settingsRaw = JSON.parse(response.data.data)
                        const settingsAvailableRaw = JSON.parse(
                            response.data.settingsAvailable
                        )[0]

                        const settingsAvailable = {
                            nomeSocial: settingsAvailableRaw.MostrarNomeSocial,
                            pronome: settingsAvailableRaw.MostrarPronome,
                            genero: settingsAvailableRaw.MostrarGenero,
                            modoNoturno:
                                settingsAvailableRaw.MostrarModoNoturno,
                            filtroCor: settingsAvailableRaw.MostrarFiltroCor,
                            contraste: settingsAvailableRaw.MostrarContraste,
                            tamanhoFonte:
                                settingsAvailableRaw.MostrarTamanhoFonte,
                            leituraLibras:
                                settingsAvailableRaw.MostrarLeituraLibras,
                            leituraVoz: settingsAvailableRaw.MostrarLeituraVoz,
                            usarBiometria:
                                settingsAvailableRaw.MostrarUsarBiometria,
                        }

                        const settings = {
                            ...settingsRaw[0],
                            isLoaded: true,
                        }

                        const loadedSettings = {
                            ...settings,
                            settingsAvailable,
                        }

                        dispatch(
                            SettingsActions.loadSettingsSetup(loadedSettings)
                        )
                    })
                } else {
                    dispatch(
                        SettingsActions.loadSettingsFailure(
                            JSON.parse(response.data.message)
                        )
                    )
                }
            })
            .catch((error: any) => {
                dispatch(
                    SettingsActions.loadSettingsFailure(
                        `Erro no Carregamento - ${error.message}`
                    )
                )
            })
    },
    loadTheme: ({
        TituloModulo,
        CorPrimaria,
        CorSecundaria,
        EsconderHeader,
    }: any) => {
        return new Promise<void>((resolve) =>
            asyncLocalStorage
                .setItem('@services_page_title', TituloModulo)
                .then(() => {
                    simplifica.colors.primary = CorPrimaria
                    simplifica.colors.secondary = CorSecundaria

                    simplifica.trainingButtons[0].color = CorPrimaria
                    simplifica.trainingButtons[1].color = CorPrimaria

                    simplifica.classifiedCategories.tipoUso[0].background =
                        CorSecundaria
                })
                .then(() =>
                    asyncLocalStorage.setItem(
                        '@services_header',
                        EsconderHeader
                    )
                )
                .finally(() => {
                    resolve()
                })
        )
    },
    loadSettings: () => async (dispatch: any) => {
        dispatch(SettingsActions.loadSettingsRequest())

        SettingsThunk.loadOptions().then((options) => {
            if (!options.internalError) {
                dispatch(SettingsActions.loadSettingsOptions(options))

                api.get('/settings/carregar')
                    .then((response: AxiosResponse) => {
                        if (response.data.state) {
                            const loadedSettings = {
                                ...JSON.parse(response.data.data),
                                isLoaded: true,
                            }
                            dispatch(
                                SettingsActions.loadSettingsSuccess(
                                    loadedSettings
                                )
                            )
                        } else {
                            dispatch(
                                SettingsActions.loadSettingsFailure(
                                    JSON.parse(response.data.message)
                                )
                            )
                        }
                    })
                    .catch((error: any) => {
                        dispatch(
                            SettingsActions.loadSettingsFailure(
                                `Erro no Carregamento - ${error.message}`
                            )
                        )
                    })
            } else {
                dispatch(
                    SettingsActions.loadSettingsFailure(options.internalError)
                )
            }
        })
    },
    loadOptions: async () => {
        return api.get('/settings/options').then((response: AxiosResponse) => {
            if (response.data.state) {
                return JSON.parse(response.data.data)
            } else {
                return {
                    internalError: response.data.internalError,
                }
            }
        })
    },
    updateSettings: (data: ISettings) => async (dispatch: any) => {
        dispatch(SettingsActions.updateSettings(true))
        api.post('/settings/gravar', data).finally(() => {
            dispatch(SettingsActions.updateSettings(false))
        })
    },
}

export default SettingsThunk
