import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { asyncLocalStorage } from '../../../auth/auth'
import { api } from '../../../services/api'
import simplifica from '../../../styles/themes/simplifica'
import * as DocumentsActions from './actions'
import { IDocumentFile, IDocumentFolder } from './types'
import AppThunk from '../app/thunk'

const handleApiError = (error: AxiosError, dispatch: Dispatch, message: string) => {
    Sentry.captureException(error)
    dispatch(DocumentsActions.loadDocumentsFailure(`${message} - ${error.message}`))
}

const updateTheme = async (data: any) => {
    const { TituloModulo, CorPrimaria, CorSecundaria } = JSON.parse(data)[0]
    await asyncLocalStorage.setItem('@services_page_title', TituloModulo)

    simplifica.colors.primary = CorPrimaria
    simplifica.colors.secondary = CorSecundaria

    simplifica.trainingButtons[0].color = CorPrimaria
    simplifica.trainingButtons[1].color = CorPrimaria

    simplifica.classifiedCategories.tipoUso[0].background = CorSecundaria
}

const parseFolders = (foldersData: string): IDocumentFolder[] => {
    const folders = JSON.parse(foldersData)
    return folders.map((folder: IDocumentFolder) => ({
        ...folder,
        brand: folder.icone.split(' ')[0] as IconPrefix,
        icon: folder.icone.split('-')[1] as IconName,
    }))
}

const DocumentsThunk = {
    loadSetup: (hash: string) => async (dispatch: Dispatch) => {
        dispatch(DocumentsActions.loadDocumentsRequest())

        try {
            const response: AxiosResponse = await api.post('/documentosrh/setup', { hash })
            if (response.data?.state) {
                await updateTheme(response.data.data)
                const header = JSON.parse(response.data.data)[0].EsconderHeader
                await asyncLocalStorage.setItem('@services_header', header)
                dispatch(DocumentsActions.loadDocumentsSetup())
            } else {
                throw new Error('Erro no Carregamento do Setup')
            }
        } catch (error) {
            handleApiError(error as AxiosError, dispatch, 'SETUP')
        }
    },

    loadFolders: () => async (dispatch: Dispatch) => {
        try {
            const response: AxiosResponse = await api.get('/documentosrh/list')
            if (response.data?.state) {
                const folders = parseFolders(response.data.data)
                if (folders.length > 0) {
                    dispatch(DocumentsActions.loadFoldersSuccess(folders))
                } else {
                    throw new Error('Não foram encontradas pastas.')
                }
            } else {
                throw new Error('Resposta inválida do servidor')
            }
        } catch (error) {
            handleApiError(error as AxiosError, dispatch, 'FOLDERS')
        }
    },

    loadFiles: (folderID: number) => async (dispatch: Dispatch) => {
        dispatch(DocumentsActions.loadDocumentsRequest())

        try {
            const colCodigo = await asyncLocalStorage.getItem('@services_colCodigo')
            const response: AxiosResponse = await api.get(`/documentosrh/list/${folderID}`)

            if (response.data?.state) {
                const files: IDocumentFile[] = JSON.parse(response.data.data)
                if (files.length > 0) {
                    files.forEach((file: IDocumentFile) => {
                        if (!colCodigo || !file.url.includes(`col_${colCodigo}`)) {
                            AppThunk.logout(Number(colCodigo))(dispatch)
                            throw new Error('Documentos não encontrados. Tente novamente mais tarde.')
                        }
                    })
                    dispatch(DocumentsActions.loadDocumentsSuccess(files))
                } else {
                    throw new Error('Não foram encontrados documentos.')
                }
            } else {
                throw new Error('Resposta inválida do servidor.')
            }
        } catch (error) {
            handleApiError(error as AxiosError, dispatch, '')
        }
    },

    showFile: (folderID: number, fileID: number) => async (dispatch: Dispatch) => {
        try {
            await api.get(`/documentosrh/show/${folderID}/${fileID}`)
        } catch (error) {
            handleApiError(error as AxiosError, dispatch, 'SHOW_FILE')
        }
    },
}

export default DocumentsThunk
