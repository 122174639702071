import React, { useEffect, useCallback, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import { ApplicationState } from '../../../store'
import DocumentsThunk from '../../../store/containers/documents/thunk'
import { IDocumentFile } from '../../../store/containers/documents/types'

import iconEnter from '../../../assets/images/enter.svg'
import { i18n } from '../../../translate/i18n'
import { Card, CardButton, CardTitle, Container, Subtitle } from './style'
import { asyncLocalStorage } from '../../../auth/auth'

const SkeletonCard: React.FC = () => (
    <Card>
        <Skeleton count={2} width={150} height={25} />
        <CardButton>
            <Skeleton width={35} />
        </CardButton>
    </Card>
)

const FileList: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { state } = useLocation<{ title: string }>()
    const dispatch = useDispatch()

    const [colCodigo, setColCodigo] = useState<string | null>(null)

    const { files, loading, error, errorMessage } = useSelector((state: ApplicationState) => ({
        files: state.documents.documentFiles,
        loading: state.documents.loading,
        error: state.documents.error,
        errorMessage: state.documents.errorMessage,
    }))

    useEffect(() => {
        if (id) {
            dispatch(DocumentsThunk.loadFiles(Number(id)))
        }
    }, [id, dispatch])

    useEffect(() => {
        asyncLocalStorage.getItem('@services_colCodigo').then(setColCodigo)
    }, [])

    const download = useCallback(
        (folderID: number, fileID: number) => {
            dispatch(DocumentsThunk.showFile(folderID, fileID))
        },
        [dispatch]
    )

    if (error) {
        return <Redirect to={{ pathname: '/error', state: { errorMessage } }} />
    }

    return (
        <>
            <Subtitle>
                <h2>{state?.title || i18n.t('documents.defaultTitle')}</h2>
            </Subtitle>
            <Container>
                {loading ? (
                    <>
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                    </>
                ) : (
                    files.map((file: IDocumentFile) => (
                        <a
                            key={file.darCodigo}
                            href={file.url}
                            target="_new"
                            rel="noreferrer"
                            download
                            onClick={(e) => {
                                if (!colCodigo || !file.url.includes(`col_${colCodigo}`)) {
                                    e.preventDefault()
                                    alert('Erro ao baixar o arquivo, tente novamente.')
                                    return
                                }
                                download(Number(id), file.darCodigo)
                            }}
                        >
                            <Card>
                                <CardTitle
                                    dangerouslySetInnerHTML={{ __html: file.referencia }}
                                />
                                <CardButton
                                    onClick={(e) => {
                                        e.preventDefault() // Evita execução dupla
                                        download(Number(id), file.darCodigo)
                                    }}
                                >
                                    <img src={iconEnter} alt="" />
                                    {i18n.t('documents.see')}
                                </CardButton>
                            </Card>
                        </a>
                    ))
                )}
            </Container>
        </>
    )
}

export default FileList
