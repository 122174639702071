import styled from 'styled-components'

export const Container = styled.div`
    padding: 16px;
`

export const Subtitle = styled.div<{ divider?: boolean }>`
    background-color: ${(props) => props.theme.colors.primary};
    padding: ${(props) => (props.divider ? '5px' : '24px')};

    > h2 {
        font-weight: bold;
        font-size: 18px;
        color: white;
    }
`

export const Card = styled.div`
    position: relative;
    padding: 32px 16px;
    background: #ffffff;
    color: ${(props) => props.theme.colors.text};
    border: 2px solid ${(props) => props.theme.colors.primary};
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    min-height: 72px;
    display: flex;
    align-items: center;

    margin-bottom: 16px;
`

export const CardIcon = styled.div`
    > svg {
        font-size: 1.75em;
        > path {
            fill: ${(props) => props.theme.colors.primary};
        }
    }
`

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

export const CardTitle = styled.h3`
    font-size: 18px;
    font-weight: 700;

    > small {
        font-weight: 400;
    }
`

export const CardSubtitle = styled.h4`
    font-size: 14px;
    font-weight: 400;
`

export const CardButton = styled.button`
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 8px;
    background-color: ${(props) => props.theme.colors.primary}15;
    border: none;
    border-radius: 10px 0px;

    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};

    > span {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: ${(props) => props.theme.colors.primary};
    }
    > img {
        margin-right: 5px;
    }
`

export const FolderCard = styled(Card)`
    gap: 25px;

    ${CardButton} {
        > a {
            > img {
                margin-right: unset;
                margin-left: 5px;
            }
        }
    }
`
