import React, { useEffect } from 'react';
import { Route, RouteProps, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { ApplicationState } from '../store';
import AppThunk from '../store/containers/app/thunk';

interface AuthRouteProps extends RouteProps { }

const AuthRoute: React.FC<AuthRouteProps> = ({ ...routeProps }) => {
    const dispatch = useDispatch();
    const { hash } = useLocation();

    const { loading, error, errormessage } = useSelector((state: ApplicationState) => ({
        loading: state.app.loading,
        error: state.app.error,
        errormessage: state.app.errormessage,
    }));

    useEffect(() => {
        const tokenHash = hash.split("#")[1];
        if (tokenHash) {
            dispatch(AppThunk.loadToken(tokenHash));
        }
    }, [hash, dispatch]);

    return (
        <Loader loading={loading} error={error} errorMessage={errormessage}>
            <Route {...routeProps} />
        </Loader>
    );
};

export default AuthRoute;
